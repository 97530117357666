<template>
    <ODataLookup :data-object="dsWBS" :whereClause="getWhere()">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                
            </span>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="OrgUnit" width="200"></OColumn>
        <OColumn field="Name" width="200"></OColumn>
        <OColumn field="Title" width="300"></OColumn>
    </ODataLookup>
</template>

<script setup>
import { onMounted } from 'vue';
import { ODataLookup } from 'o365-datalookup';

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,
    OrgUnitID: Number,
    whereClause: String
});

const dsWBS = props.OrgUnitID ? $getDataObjectById("dsCost_WBSDescendantsLookup") : $getDataObjectById("dsCost_WBSLookup");

onMounted(() => {
    if (!props.OrgUnitID) {
        dsWBS.enableContextFilter({idPathField:"OrgUnitIdPath"});
    }
});

const getWhere = () => {
    const clauses = [];

    if (props.whereClause) {
        clauses.push(props.whereClause);
    }

    if (props.OrgUnitID) {
        clauses.push(`AccessOrgUnit_ID = ${props.OrgUnitID}`);
    }

    return clauses.join(' AND ');
};
</script>